import { AntDesign, Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import {
  Button,
  Flex,
  Icon,
  IconButton,
  Image,
  useBreakpointValue,
} from "native-base";
import { useAuthState } from "react-firebase-hooks/auth";
import { Pressable } from "react-native";

import { auth } from "../common/firebase";
import { Logo } from "./Logo";

export function Header() {
  const navigation = useNavigation();
  const [user] = useAuthState(auth);

  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  return (
    <Flex
      direction="row"
      padding={4}
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      maxW="1024"
      marginX="auto"
    >
      <Pressable onPress={() => navigation.navigate("Tasks")}>
        <Logo />
      </Pressable>

      <Flex direction="row">
        {user && isMobile && (
          <>
            <IconButton
              size="lg"
              icon={<Icon as={AntDesign} name="tago" />}
              onPress={() => navigation.navigate("Categories")}
            />
            <IconButton
              size="lg"
              icon={<Icon as={AntDesign} name="setting" />}
              onPress={() => navigation.navigate("Settings")}
            />
          </>
        )}

        {user && !isMobile && (
          <>
            <Button
              size="lg"
              variant="link"
              onPress={() => navigation.navigate("New Task")}
            >
              New Task
            </Button>
            <Button
              size="lg"
              variant="link"
              onPress={() => navigation.navigate("Categories")}
            >
              Categories
            </Button>
            <Button
              size="lg"
              variant="link"
              onPress={() => navigation.navigate("Settings")}
            >
              Settings
            </Button>
          </>
        )}

        {!user && (
          <Button
            size="lg"
            variant="link"
            onPress={() => navigation.navigate("Login")}
          >
            Login
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
