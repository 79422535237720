import { useState } from "react";

import { auth } from "../common/firebase";

type Args = {
  method: "GET" | "POST" | "PATCH" | "DELETE";
  url: string;
};

type IBody = object;
type IOnSuccess<T> = (result: T) => void;
type IOnError = (error: string) => void;

export type IMakeFetch<T> = (
  body?: IBody,
  onSuccess?: IOnSuccess<T>,
  onError?: IOnError
) => Promise<void>;

type IResult<T> = T | undefined;
type ILoading = boolean;
export type IError = string | undefined;

type IReturn<T> = [IMakeFetch<T>, IResult<T>, ILoading, IError];

export default function useMakeFetch<T>({ method, url }: Args): IReturn<T> {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<T>();
  const [error, setError] = useState<string>();

  const fetchRequest = async (
    body?: IBody,
    onSuccess?: IOnSuccess<T>,
    onError?: IOnError
  ) => {
    setLoading(true);
    await makeFetch(
      { body, url, method },
      (result: T) => {
        setResult(result);
        onSuccess && onSuccess(result);
      },
      (error: string) => {
        setResult(undefined);
        setError(error);
        onError && onError(error);
      }
    );
    setLoading(false);
  };

  return [fetchRequest, result, loading, error];
}

export async function makeFetch<T>(
  { body, url, method }: { body?: IBody } & Args,
  onSuccess?: IOnSuccess<T>,
  onError?: IOnError
) {
  const token = await auth.currentUser?.getIdToken();

  if (token) {
    const response = await fetch(url, {
      method,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      ...(body && { body: JSON.stringify(body) }),
    });

    if (response.status === 200) {
      const result: T = await response.json();
      onSuccess && onSuccess(result);
      return result;
    } else {
      const error: string | { message: string } = await response.json();
      onError && onError(typeof error === "string" ? error : error.message);
      return error;
    }
  } else {
    const error = "no token provided";
    onError && onError(error);
    return error;
  }
}
