import { FontAwesome } from "@expo/vector-icons";
import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../common/firebase";
import { useTasksContext } from "../contexts/TasksContext";

export function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const [
    _,
    {
      handleFetchCategories,
      handleFetchTasks,
      handleSetSampleCategories,
      handleSetSampleTasks,
    },
  ] = useTasksContext();
  const [user] = useAuthState(auth);

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();

        // Load fonts
        await Font.loadAsync({
          ...FontAwesome.font,
          "space-mono": require("../../assets/fonts/SpaceMono-Regular.ttf"),
        });

        if (user?.uid) {
          setLoadingComplete(false);
          await handleFetchTasks();
          await handleFetchCategories();
        } else {
          handleSetSampleCategories();
          handleSetSampleTasks();
        }
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, [user?.uid]);

  return isLoadingComplete;
}
