import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { Flex, Icon, IconButton, Pressable, Stack, Text } from "native-base";

import { useTasksContext } from "../../../contexts/TasksContext";
import { makeFetch } from "../../../hooks/useMakeFetch";
import { Status, Task } from "../../../types/Task";

export const getActionFromStatus = (status: Status) => {
  switch (status) {
    case "completed":
      return { opacity: 1, name: "circle", newStatus: "started" };
    case "started":
      return { opacity: 0.6, name: "circle", newStatus: "created" };
    case "created":
    default:
      return { opacity: 0.6, name: "circle-outline", newStatus: "completed" };
  }
};

export function TaskCard({ status, title, taskId, categoryId }: Task) {
  const [_, { handleFetchTasks }, { getCategoryFromId }] = useTasksContext();
  const navigation = useNavigation();
  const { opacity, newStatus, name } = getActionFromStatus(status);

  const category = getCategoryFromId(categoryId);

  const handleUpdateStatus = async () => {
    await makeFetch({
      url: `https://task-service.vercel.app/api/tasks/${taskId}`,
      method: "PATCH",
      body: {
        status: newStatus,
      },
    });
    handleFetchTasks();
  };

  return (
    <Stack direction="row" alignItems="start" space={1} width="100%">
      <IconButton
        opacity={opacity}
        alignSelf="start"
        icon={<Icon as={MaterialCommunityIcons} name={name} size="md" />}
        onPress={handleUpdateStatus}
        marginLeft={-3}
      />

      <Pressable
        // @ts-ignore
        onPress={() => navigation.navigate("Edit Task", { taskId })}
        isDisabled={status === "completed"}
        style={{ flex: 1 }}
        _hover={{
          textDecoration: "underline",
        }}
      >
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flex={1}
        >
          <Text
            strikeThrough={status === "completed"}
            opacity={status === "completed" ? 0.6 : 1}
            fontSize="md"
            paddingY={2}
          >
            {title}
          </Text>

          <Text fontSize="xl" width={6} marginRight={1.5}>
            {category?.emoji}
          </Text>
        </Flex>
      </Pressable>
    </Stack>
  );
}
