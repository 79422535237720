import { useNavigation } from "@react-navigation/native";
import { signOut } from "firebase/auth";
import { Button } from "native-base";

import { auth } from "../../common/firebase";
import { LayoutWithBackButton } from "../../components/LayoutWithBackButton";

export function SettingsScreen() {
  const navigation = useNavigation();
  return (
    <LayoutWithBackButton title="Settings">
      <Button
        colorScheme="black"
        size="lg"
        marginLeft={-3}
        alignSelf="start"
        variant="link"
        onPress={() => {
          signOut(auth);
          navigation.navigate("Login");
        }}
      >
        Log Out
      </Button>
    </LayoutWithBackButton>
  );
}
