import { Button } from "native-base";

import { useTasksContext } from "../../../contexts/TasksContext";
import { makeFetch } from "../../../hooks/useMakeFetch";

export function NewCategoryButton() {
  const [_, { handleFetchCategories }] = useTasksContext();

  const handleCreateCategory = async (body: object) => {
    await makeFetch({
      url: `https://task-service.vercel.app/api/categories`,
      method: "POST",
      body: { title: "" },
    });
    await handleFetchCategories();
  };

  return (
    <Button
      size="lg"
      mt={2}
      variant="link"
      justifyContent="start"
      padding={0}
      onPress={handleCreateCategory}
    >
      New Category
    </Button>
  );
}
