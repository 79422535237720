import { Ionicons } from "@expo/vector-icons";
import { Button, Center, Icon, IconButton, Modal } from "native-base";
import { useState } from "react";

import { useTasksContext } from "../../../contexts/TasksContext";
import { makeFetch } from "../../../hooks/useMakeFetch";

type Props = {
  categoryId: string;
};

export function DeleteCategoryModal({ categoryId }: Props) {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [_, { handleFetchCategories }] = useTasksContext();

  const handleDeleteCategory = async (body: object) => {
    setIsLoading(true);
    await makeFetch({
      url: `https://task-service.vercel.app/api/categories/${categoryId}`,
      method: "DELETE",
    });
    await handleFetchCategories();
  };

  return (
    <Center>
      <IconButton
        icon={<Icon as={Ionicons} name="trash-outline" />}
        colorScheme="danger"
        size="lg"
        onPress={() => setShowModal(true)}
      />
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Content maxWidth="400px">
          <Modal.Header borderBottomWidth={0}>
            Delete this category?
          </Modal.Header>
          <Modal.Body borderTopWidth={0}>
            <Button.Group space={2}>
              <Button
                flex={1}
                size="lg"
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  setShowModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                flex={1}
                size="lg"
                colorScheme="danger"
                isLoading={isLoading}
                onPress={handleDeleteCategory}
              >
                Delete
              </Button>
            </Button.Group>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </Center>
  );
}
