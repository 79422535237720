import moment from "moment";

import { Task } from "../types/Task";

export const getInitialTasks = (): Task[] => [
  {
    userId: "1234",
    taskId: "1",
    title: "This is a task you need to complete",
    dateDue: moment().endOf("day").toISOString(),
    dateCreated: moment().endOf("day").toISOString(),
    status: "created",
  },
  {
    userId: "1234",
    taskId: "2",
    title: "This one's in progress",
    dateDue: moment().endOf("day").toISOString(),
    dateCreated: moment().endOf("day").toISOString(),
    status: "started",
  },
  {
    userId: "1234",
    taskId: "3",
    title: "And this one's done!",
    dateDue: moment().endOf("day").toISOString(),
    dateCreated: moment().endOf("day").toISOString(),
    status: "completed",
  },
  {
    userId: "1234",
    taskId: "4",
    categoryId: "1",
    title: "And this task is due tmr",
    dateDue: moment().add(1, "day").endOf("day").toISOString(),
    dateCreated: moment().add(1, "day").endOf("day").toISOString(),
    status: "created",
  },
  {
    userId: "1234",
    taskId: "5",
    title: "They may contain a category",
    dateDue: moment().add(1, "day").endOf("day").toISOString(),
    dateCreated: moment().add(1, "day").endOf("day").toISOString(),
    status: "created",
  },
  {
    userId: "1234",
    taskId: "6",
    categoryId: "2",
    title: "To help you organise yourself better",
    dateDue: moment().add(1, "day").endOf("day").toISOString(),
    dateCreated: moment().add(1, "day").endOf("day").toISOString(),
    status: "completed",
  },
];
