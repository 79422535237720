import moment from "moment";
import { Flex, ScrollView, Text } from "native-base";

import { DayList } from "../components/DayList";

const getDays = () => {
  const monday = moment().startOf("isoWeek").add(7, "d");

  return [...Array(7).keys()].map((index) => ({
    currentMoment: moment(monday).add(index, "days"),
    relativeIndex: 1,
  }));
};

export function UpcomingScreen() {
  return (
    <ScrollView backgroundColor="white" flex={1}>
      <Flex
        maxW="1024px"
        width="100%"
        marginX="auto"
        flexDirection={{ base: "column", lg: "row" }}
        flexWrap="wrap"
        flex={1}
      >
        <Text
          fontSize="3xl"
          fontWeight="light"
          padding={4}
          width={{ base: "100%", md: "50%", lg: "33%" }}
        >
          Next Week
        </Text>
        <Flex
          w="100%"
          direction="row"
          flexWrap="wrap"
          width={{ base: "100%", md: "100%", lg: "67%" }}
        >
          {getDays().map((day) => (
            <DayList key={day.currentMoment.format("dddd")} {...day} />
          ))}
        </Flex>
      </Flex>
    </ScrollView>
  );
}
