import { LinkingOptions } from "@react-navigation/native";
import * as Linking from "expo-linking";

import { RootStackParamList } from "../types/types";

export const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      Tasks: {
        screens: {
          Previous: "tasks/previous",
          Upcoming: "tasks/upcoming",
          "This Week": "tasks",
        },
      },
      Settings: "settings",
      Categories: "categories",
      Login: "login",
      "New Task": "new-task",
      "Edit Task": "edit-task",
      NotFound: "*",
    },
  },
};
