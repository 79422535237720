import { useNavigation } from "@react-navigation/native";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Alert, Button, Center, HStack, Input, Text, useBreakpointValue } from "native-base";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { SafeAreaView } from "react-native";

import { auth } from "../../common/firebase";
import { LayoutWithBackButton } from "../../components/LayoutWithBackButton";

export function LoginScreen() {
  const [errorMessage, setErrorMessage] = useState("");
  const navigation = useNavigation();

  const signIn = (data: any) => {
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then(() => {
        navigation.navigate("Tasks");
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  return (
    <>
      <LayoutWithBackButton title="Login">
        <Center width="100%" maxW="md">
          {errorMessage && (
            <Alert w="100%" status="error">
              <HStack space={2} flexShrink={1}>
                <Alert.Icon mt="1" />
                <Text fontSize="md" color="coolGray.800">
                  {errorMessage}
                </Text>
              </HStack>
            </Alert>
          )}
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <Input
                  padding={4}
                  marginTop={4}
                  width="100%"
                  size="lg"
                  autoComplete="email"
                  variant="filled"
                  borderRadius={0}
                  borderWidth={0}
                  backgroundColor="white"
                  onBlur={onBlur}
                  placeholder="Email"
                  onChangeText={onChange}
                  value={value}
                />
              );
            }}
            name="email"
            rules={{ required: "Field is required", minLength: 3 }}
          />

          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <Input
                  padding={4}
                  marginTop={4}
                  size="lg"
                  width="100%"
                  variant="filled"
                  borderRadius={0}
                  borderWidth={0}
                  backgroundColor="white"
                  onBlur={onBlur}
                  autoComplete="password"
                  type="password"
                  placeholder="Password"
                  onChangeText={onChange}
                  value={value}
                />
              );
            }}
            name="password"
            rules={{ required: "Field is required", minLength: 3 }}
          />

          <Button
            variant="link"
            size="md"
            alignSelf="end"
            onPress={() => navigation.navigate("ForgotPassword")}
          >
            Forgot your password?
          </Button>

          {isMobile || (
            <Button
              size="lg"
              marginTop={8}
              borderRadius={0}
              onPress={handleSubmit(signIn)}
              isDisabled={!isDirty}
              width="100%"
            >
              Login
            </Button>
          )}
        </Center>
      </LayoutWithBackButton>
      {isMobile && (
        <Button
          size="lg"
          borderRadius={0}
          onPress={handleSubmit(signIn)}
          isDisabled={!isDirty}
        >
          Login
        </Button>
      )}

      <SafeAreaView
        style={{
          flex: 0,
          backgroundColor: !isDirty ? "#9CD3E0" : "#0891b2",
        }}
      />
    </>
  );
}
