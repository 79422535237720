import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";

import { getInitialCategories } from "../consts/getInitialCategories";
import { getInitialTasks } from "../consts/getInitialTasks";
import { makeFetch } from "../hooks/useMakeFetch";
import { Category } from "../types/Category";
import { Task } from "../types/Task";

interface ITasksContext {
  state: {
    tasks: Task[];
    categories: Category[];
    isLoading: boolean;
  };
  handlers: {
    handleFetchTasks: () => Promise<void>;
    handleFetchCategories: () => Promise<void>;
    handleSetSampleTasks: () => void;
    handleSetSampleCategories: () => void;
  };
  selectors: {
    getTaskFromId: (taskId: string) => Task | undefined;
    getCategoryFromId: (categoryId?: string) => Category | undefined;
  };
}

export const TasksContext = createContext<ITasksContext>({
  state: {
    categories: [],
    tasks: [],
    isLoading: true,
  },
  handlers: {
    handleFetchTasks: () => new Promise(() => {}),
    handleFetchCategories: () => new Promise(() => {}),
    handleSetSampleTasks: () => {},
    handleSetSampleCategories: () => {},
  },
  selectors: {
    getTaskFromId: () => undefined,
    getCategoryFromId: () => undefined,
  },
});

export function TasksContextProvider({ children }: PropsWithChildren<{}>) {
  const [isLoadingCategories, setIsLoadingCategories] = useState<boolean>(true);
  const [isLoadingTasks, setIsLoadingTasks] = useState<boolean>(true);
  const [categories, setCategories] = useState<Category[]>();
  const [tasks, setTasks] = useState<Task[]>();

  const handleSetSampleTasks = () => {
    setIsLoadingTasks(true);
    setTasks(getInitialTasks());
    setIsLoadingTasks(false);
  };

  const handleSetSampleCategories = () => {
    setIsLoadingCategories(true);
    setCategories(getInitialCategories());
    setIsLoadingCategories(false);
  };

  const fetchCategories = () =>
    makeFetch<Category[]>(
      {
        url: "https://task-service.vercel.app/api/categories",
        method: "GET",
      },
      (data) => {
        setCategories(data);
      }
    );

  const fetchTasks = () =>
    makeFetch<Task[]>(
      {
        url: "https://task-service.vercel.app/api/tasks",
        method: "GET",
      },
      (data) => {
        setTasks(data);
      }
    );

  const handleFetchCategories = async () => {
    setIsLoadingCategories(true);
    await fetchCategories();
    setIsLoadingCategories(false);
  };

  const handleFetchTasks = async () => {
    setIsLoadingTasks(true);
    await fetchTasks();
    setIsLoadingTasks(false);
  };

  const getTaskFromId = (taskId: string) =>
    tasks?.find((task) => task.taskId === taskId);

  const getCategoryFromId = (categoryId?: string) => {
    if (categoryId)
      return categories?.find((category) => category.categoryId === categoryId);
  };

  return (
    <TasksContext.Provider
      value={{
        state: {
          categories: categories || [],
          tasks: tasks || [],
          isLoading: isLoadingTasks || isLoadingCategories,
        },
        handlers: {
          handleFetchTasks,
          handleSetSampleTasks,
          handleFetchCategories,
          handleSetSampleCategories,
        },
        selectors: { getTaskFromId, getCategoryFromId },
      }}
    >
      {children}
    </TasksContext.Provider>
  );
}

export function useTasksContext(): [
  ITasksContext["state"],
  ITasksContext["handlers"],
  ITasksContext["selectors"]
] {
  const { state, handlers, selectors } = useContext(TasksContext);
  return [state, handlers, selectors];
}
