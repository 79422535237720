import moment from "moment";
import { Divider, Flex, ScrollView, Text } from "native-base";

import { DayList } from "../components/DayList";

const getDays = () => {
  const monday = moment().startOf("isoWeek");
  const today = moment();

  return [...Array(7).keys()].map((index) => ({
    currentMoment: moment(monday).add(index, "days"),
    isPast: today.isoWeekday() - index - 1 > 0,
    relativeIndex: index + 1 - today.isoWeekday(),
  }));
};

export function ThisWeekScreen() {
  return (
    <ScrollView backgroundColor="white" flex={1}>
      <Flex
        maxW="1024px"
        width="100%"
        marginX="auto"
        flexDirection={{ base: "column", lg: "row" }}
        flexWrap="wrap"
        flex={1}
      >
        <Text
          fontSize="3xl"
          fontWeight="light"
          padding={4}
          width={{ base: "100%", md: "50%", lg: "33%" }}
        >
          This Week
        </Text>
        <Flex
          w="100%"
          direction="row"
          flexWrap="wrap"
          width={{ base: "100%", md: "100%", lg: "67%" }}
        >
          {getDays()
            .filter(({ isPast }) => !isPast)
            .map((day) => (
              <DayList key={day.currentMoment.format("dddd")} {...day} />
            ))}
        </Flex>
      </Flex>

      <Divider w="100%" maxW="1024px" marginX="auto" bgColor="#0891B230" />

      <Flex
        w="100%"
        maxW="1024px"
        marginX="auto"
        paddingY={8}
        direction="row"
        flexWrap="wrap"
      >
        <Text
          fontSize="3xl"
          width={{ base: "100%", md: "50%", lg: "33%" }}
          fontWeight="light"
          padding={4}
        >
          Earlier This Week
        </Text>

        <Flex
          w="100%"
          direction="row"
          flexWrap="wrap"
          width={{ base: "100%", md: "100%", lg: "67%" }}
        >
          {getDays()
            .filter(({ isPast }) => isPast)
            .map((day) => (
              <DayList key={day.currentMoment.format("dddd")} {...day} />
            ))}
        </Flex>
      </Flex>
    </ScrollView>
  );
}
