import { SafeAreaView } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import { ThisWeekScreen } from "./screens/ThisWeekScreen";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import { PreviousScreen } from "./screens/PreviousScreen";
import { UpcomingScreen } from "./screens/UpcomingScreen";

const Tab = createBottomTabNavigator();

export function TasksNavigation() {
  return (
    <Tab.Navigator
      initialRouteName="This Week"
      screenOptions={{
        headerShown: false,
        tabBarStyle: {
          backgroundColor: "white",
          borderTopColor: "#0891B230",
          paddingTop: 4,
        },
        tabBarActiveTintColor: "#0891b2",
      }}
    >
      <Tab.Screen
        name="Previous"
        component={PreviousScreen}
        options={{
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons
              name="page-first"
              size={size}
              color={color}
            />
          ),
        }}
      />
      <Tab.Screen
        name="This Week"
        component={ThisWeekScreen}
        options={{
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons
              name="chevron-down-box-outline"
              size={size}
              color={color}
            />
          ),
        }}
      />
      <Tab.Screen
        name="Upcoming"
        options={{
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons
              name="page-last"
              size={size}
              color={color}
            />
          ),
        }}
        component={UpcomingScreen}
      />
    </Tab.Navigator>
  );
}
