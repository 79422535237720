import moment from "moment";

import { Category } from "../types/Category";

export const getInitialCategories = (): Category[] => [
  {
    userId: "1234",
    title: "Travel",
    emoji: "🌎",
    categoryId: "1",
    order: 0,
  },
  {
    userId: "1234",
    title: "B-Day",
    emoji: "🎂",
    categoryId: "2",
    order: 0,
  },
];
