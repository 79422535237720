import { useRoute } from "@react-navigation/native";

import { TaskForm } from "../components/TaskForm";

export function NewTaskScreen() {
  const route = useRoute<any>();

  if (route.params) {
    const { dateDue } = route.params;
    return <TaskForm task={{ dateDue }} />;
  }

  return <TaskForm />;
}
