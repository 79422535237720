import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { StatusBar } from "expo-status-bar";
import { NativeBaseProvider } from "native-base";
import { SafeAreaView } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";

import { Header } from "./src/components/Header";
import { linking } from "./src/consts/LinkingConfiguration";
import { TasksContextProvider } from "./src/contexts/TasksContext";
import { UserContextProvider } from "./src/contexts/UserContext";
import { useCachedResources } from "./src/hooks/useCachedResources";
import { CategoriesScreen } from "./src/screens/categories/CategoriesScreen";
import { LoginScreen } from "./src/screens/login/LoginScreen";
import { NotFoundScreen } from "./src/screens/not-found/NotFoundScreen";
import { SettingsScreen } from "./src/screens/settings/SettingsScreen";
import { SplashScreen } from "./src/screens/splash/SplashScreen";
import { EditTaskScreen } from "./src/screens/task/screens/EditTaskScreen";
import { NewTaskScreen } from "./src/screens/task/screens/NewTaskScreen";
import { TasksNavigation } from "./src/screens/tasks/TasksNavigation";
import { RootStackParamList } from "./src/types/types";

export default function App() {
  return (
    <UserContextProvider>
      <TasksContextProvider>
        <NativeBaseProvider>
          <SafeAreaProvider>
            <Navigation />
          </SafeAreaProvider>
        </NativeBaseProvider>
      </TasksContextProvider>
    </UserContextProvider>
  );
}

export function Navigation() {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return <SplashScreen />;
  } else {
    return (
      <>
        <NavigationContainer linking={linking}>
          <SafeAreaView style={{ flex: 0, backgroundColor: "#ffffff" }} />
          <Header />
          <RootNavigator />
        </NavigationContainer>
        <StatusBar />
      </>
    );
  }
}

const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator
      screenOptions={{
        contentStyle: {
          backgroundColor: "#FFFFFF",
        },
        headerShown: false,
      }}
    >
      <Stack.Screen name="Tasks" component={TasksNavigation} />
      <Stack.Screen name="Settings" component={SettingsScreen} />
      <Stack.Screen name="Categories" component={CategoriesScreen} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} />
      <Stack.Screen name="Login" component={LoginScreen} />
      <Stack.Group>
        <Stack.Screen name="New Task" component={NewTaskScreen} />
        <Stack.Screen name="Edit Task" component={EditTaskScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
}
