import { Ionicons } from "@expo/vector-icons";
import { Flex, Icon, IconButton, Input, Stack } from "native-base";
import { Controller, useForm } from "react-hook-form";

import { useTasksContext } from "../../../contexts/TasksContext";
import { makeFetch } from "../../../hooks/useMakeFetch";
import { Category } from "../../../types/Category";

import { DeleteCategoryModal } from "./DeleteCategoryModal";

type Props = Category;

export function CategoryListItem({ emoji, title, categoryId }: Props) {
  const [_, { handleFetchCategories }] = useTasksContext();

  const { control } = useForm({
    defaultValues: { emoji, title },
  });

  const handleUpdateCategory = async (body: object) => {
    await makeFetch({
      body,
      url: `https://task-service.vercel.app/api/categories/${categoryId}`,
      method: "PATCH",
    });
    await handleFetchCategories();
  };

  return (
    <Flex
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      marginX={-3}
    >
      <Stack direction="row" width="50%">
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <Input
                width={10}
                size="lg"
                variant="unstyled"
                onBlur={() => {
                  onBlur();
                  handleUpdateCategory({ emoji: value });
                }}
                onChangeText={onChange}
                value={value}
              />
            );
          }}
          name="emoji"
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <Input
                marginLeft={-2}
                size="lg"
                variant="unstyled"
                borderRadius={0}
                onBlur={() => {
                  onBlur();
                  handleUpdateCategory({ title: value });
                }}
                placeholder="Category Name"
                onChangeText={onChange}
                value={value}
              />
            );
          }}
          name="title"
        />
      </Stack>
      <Flex direction="row">
        <IconButton icon={<Icon as={Ionicons} name="menu-outline" />} />
        <DeleteCategoryModal categoryId={categoryId} />
      </Flex>
    </Flex>
  );
}
