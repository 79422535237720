import { LayoutWithBackButton } from "../../components/LayoutWithBackButton";
import { useTasksContext } from "../../contexts/TasksContext";

import { CategoryListItem } from "./components/CategoryListItem";
import { NewCategoryButton } from "./components/NewCategoryButton";

export function CategoriesScreen() {
  const [{ categories }] = useTasksContext();

  return (
    <LayoutWithBackButton title="Categories">
      {categories.map((category) => (
        <CategoryListItem key={category.categoryId} {...category} />
      ))}
      <NewCategoryButton />
    </LayoutWithBackButton>
  );
}
