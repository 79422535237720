import { AntDesign } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import moment, { Moment } from "moment";
import { Flex, Icon, IconButton, Stack, Text } from "native-base";

import { useTasksContext } from "../../../contexts/TasksContext";

import { TaskCard } from "../components/TaskCard";

const statuses = ["completed", "started", "created"];

type Props = {
  currentMoment: Moment;
  relativeIndex: number;
};

export function DayList({ currentMoment, relativeIndex }: Props) {
  const [{ tasks }] = useTasksContext();
  const navigation = useNavigation();

  const isToday = relativeIndex === 0;
  const isPast = relativeIndex < 0;
  const isFuture = relativeIndex > 0;

  const dayTasks = tasks
    .sort((a, b) => a.title.localeCompare(b.title))
    .sort((_, b) => (b?.categoryId ? 1 : -1))
    .sort((a, b) => statuses.indexOf(b.status) - statuses.indexOf(a.status))
    .filter((task) => {
      if (isPast) {
        return (
          task.status === "completed" &&
          moment(task.dateCompleted).isSame(currentMoment, "d")
        );
      } else if (isToday) {
        return (
          (task.status === "completed" &&
            moment(task.dateCompleted).isSame(currentMoment, "d")) ||
          ((task.status === "started" || task.status === "created") &&
            moment(task.dateDue).isSameOrBefore(currentMoment, "d"))
        );
      } else if (isFuture) {
        return (
          (task.status === "completed" &&
            moment(task.dateCompleted).isSame(currentMoment, "d")) ||
          ((task.status === "started" || task.status === "created") &&
            moment(task.dateDue).isSame(currentMoment, "d"))
        );
      }
    });

  return (
    <Stack
      width={{ base: "100%", md: "50%" }}
      backgroundColor={isToday ? "#eff6f7" : "transparent"}
      paddingX={4}
      paddingY={6}
    >
      <Flex direction="row" alignItems="center" justifyContent="space-between">
        <Text
          fontSize="2xl"
          fontWeight="light"
          marginBottom={dayTasks.length ? 2 : 0}
        >
          {isToday ? "Today" : currentMoment.format("ddd, D MMM")}
        </Text>
        {isPast || (
          <IconButton
            icon={<Icon as={AntDesign} name="plus" />}
            onPress={() =>
              //@ts-ignore
              navigation.navigate("New Task", {
                dateDue: moment()
                  .add(relativeIndex, "day")
                  .endOf("day")
                  .toISOString(),
              })
            }
          />
        )}
      </Flex>

      {dayTasks.map((task) => (
        <TaskCard key={task.taskId} {...task} />
      ))}
    </Stack>
  );
}
