import { useRoute } from "@react-navigation/native";

import { useTasksContext } from "../../../contexts/TasksContext";

import { TaskForm } from "../components/TaskForm";

export function EditTaskScreen() {
  const [{ isLoading }, _, { getTaskFromId }] = useTasksContext();
  const route = useRoute<any>();

  if (!isLoading && route.params.taskId) {
    const task = getTaskFromId(route.params.taskId);

    return <TaskForm task={task} isEditMode />;
  }

  return null;
}
