import { AntDesign } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { Button, Flex, Icon, ScrollView, Stack, Text, useBreakpointValue } from "native-base";
import { PropsWithChildren } from "react";
import { SafeAreaView } from "react-native";

type Props = {
  title: string;
};

export function LayoutWithBackButton({
  title,
  children,
}: PropsWithChildren<Props>) {
  const navigation = useNavigation();

  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  return (
    <SafeAreaView
      style={{ flex: 1, height: "100%", backgroundColor: "#eff6f7" }}
    >
      <ScrollView>
        <Stack
          w="100%"
          height="100%"
          maxW="1024"
          marginX="auto"
          space={4}
          paddingX={6}
          paddingY={4}
        >
          <Flex w="100%">
            {isMobile && (
              <Button
                size="lg"
                marginLeft={-3}
                alignSelf="start"
                variant="link"
                onPress={() =>
                  navigation.canGoBack()
                    ? navigation.goBack()
                    : navigation.navigate("Tasks")
                }
                leftIcon={<Icon as={AntDesign} name="arrowleft" />}
              >
                Back
              </Button>
            )}

            <Text fontSize="3xl" fontWeight="light">
              {title}
            </Text>
          </Flex>

          <Flex w="100%">{children}</Flex>
        </Stack>
      </ScrollView>
    </SafeAreaView>
  );
}
