import { Center, Image } from "native-base";

export function SplashScreen() {
  return (
    <Center backgroundColor="white" flex={1}>
      <Image
        alt="Loading the app"
        source={require("../../../assets/images/splash.png")}
        width="100%"
        height="100%"
      />
    </Center>
  );
}
