import { createContext, PropsWithChildren, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../common/firebase";

interface IUserContext {
  state: {
    userId: string;
    email: string;
    isLoading: boolean;
    hasFetched: boolean;
  };
  handlers: { handleLogOut: () => void };
}

export const UserContext = createContext<IUserContext>({
  state: {
    userId: "",
    email: "",
    isLoading: true,
    hasFetched: false,
  },
  handlers: {
    handleLogOut: () => undefined,
  },
});

export function UserContextProvider({ children }: PropsWithChildren<{}>) {
  const [user, isLoading] = useAuthState(auth);

  const userId = user?.uid || "";

  const email = user?.email || "";

  const hasFetched = !!userId;

  const handleLogOut = () => {
    auth.signOut();
  };

  return (
    <UserContext.Provider
      value={{
        state: {
          userId,
          email,
          isLoading,
          hasFetched,
        },
        handlers: { handleLogOut },
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext(): [
  IUserContext["state"],
  IUserContext["handlers"]
] {
  const { state, handlers } = useContext(UserContext);
  return [state, handlers];
}
