import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import moment from "moment";
import {
    Box, Button, Flex, Icon, IconButton, Input, ScrollView, Select, Stack, Text, TextArea,
    useBreakpointValue
} from "native-base";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Platform, Pressable, SafeAreaView } from "react-native";
import Collapsible from "react-native-collapsible";

import { LayoutWithBackButton } from "../../../components/LayoutWithBackButton";
import { useTasksContext } from "../../../contexts/TasksContext";
import { makeFetch } from "../../../hooks/useMakeFetch";
import { Task } from "../../../types/Task";

import { getActionFromStatus } from "../../tasks/components/TaskCard";

import { DateTimePicker } from "./DateTimePicker";
import { DeleteTaskModal } from "./DeleteTaskModal";

type Props = { task?: Partial<Task>; isEditMode?: boolean };

export function TaskForm({ task, isEditMode = false }: Props) {
  const [{ categories }, { handleFetchTasks }] = useTasksContext();

  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: isEditMode
      ? task
      : {
          title: "",
          description: "",
          categoryId: "",
          parentTaskId: "",
          status: "created",
          dateDue: moment().add(1, "day").endOf("day").toISOString(),
        },
  });

  const [shouldShowDesc, setShouldShowDesc] = useState(isEditMode || !isMobile);
  const [shouldShowCategory, setShouldShowCategory] = useState(
    isEditMode || !isMobile
  );
  const [shouldShowLinks, setShouldShowLinks] = useState(
    isEditMode || !isMobile
  );
  const [isLoading, setIsLoading] = useState(false);

  const navigation = useNavigation();

  const handleSaveTask = async (body: object) => {
    setIsLoading(true);
    await makeFetch({
      url: `https://task-service.vercel.app/api/tasks${
        isEditMode ? "/" + task!.taskId : ""
      }`,
      method: isEditMode ? "PATCH" : "POST",
      body,
    });
    await handleFetchTasks();
    setIsLoading(false);
    navigation.navigate("Tasks");
  };

  return (
    <>
      <LayoutWithBackButton title={isEditMode ? "Edit Task" : "New Task"}>
        <ScrollView>
          <Flex maxW="md">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => {
                const noOfDays = moment(value).diff(moment(), "days");
                let label;
                if (noOfDays === 0) {
                  label = "Today";
                } else if (noOfDays === 1) {
                  label = "Tomorrow";
                } else if (noOfDays === -1) {
                  label = "Yesterday";
                } else if (noOfDays > 1) {
                  label = `in ${noOfDays} days`;
                } else {
                  label = `${noOfDays} days ago`;
                }

                return (
                  <Flex
                    direction="row"
                    alignItems="center"
                    marginTop={4}
                    justifyContent="space-between"
                  >
                    <Flex direction="row" alignItems="center" maxWidth="50%">
                      <Box paddingRight={3}>
                        <Icon
                          color="#0891B2"
                          as={AntDesign}
                          size="lg"
                          name="calendar"
                        />
                      </Box>
                      <DateTimePicker date={value} onChange={onChange} />
                    </Flex>
                    <Text>{label}</Text>
                  </Flex>
                );
              }}
              name="dateDue"
            />
            <Flex w="100%">
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <Input
                      padding={4}
                      marginTop={4}
                      borderRadius={0}
                      backgroundColor="white"
                      size="lg"
                      variant="filled"
                      onBlur={onBlur}
                      placeholder="New Task"
                      onChangeText={onChange}
                      value={value}
                    />
                  );
                }}
                name="title"
                rules={{ required: "Field is required", minLength: 3 }}
              />

              <Collapsible collapsed={!shouldShowDesc}>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextArea
                      marginTop={4}
                      padding={4}
                      borderRadius={0}
                      backgroundColor="white"
                      size="lg"
                      variant="filled"
                      autoCompleteType="off"
                      placeholder="Add details"
                      onChangeText={(val) => onChange(val)}
                      defaultValue={value}
                    />
                  )}
                  name="description"
                />
              </Collapsible>

              <Collapsible collapsed={!shouldShowCategory}>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      //@ts-ignore
                      selectedValue={value}
                      marginTop={Platform.OS === "web" ? 4 : 2}
                      padding={4}
                      borderRadius={0}
                      borderWidth={0}
                      backgroundColor="white"
                      size="lg"
                      variant="filled"
                      onValueChange={onChange}
                    >
                      {categories.map((category) => (
                        <Select.Item
                          label={category.emoji + "  " + category.title}
                          value={category.categoryId}
                          key={category.categoryId}
                        />
                      ))}
                      <Select.Item label="No Category" value="" />
                    </Select>
                  )}
                  name="categoryId"
                  defaultValue="js"
                />
              </Collapsible>

              {/* <Collapsible collapsed={!shouldShowLinks}>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder="Parent Task"
                      selectedValue={value}
                      marginTop={Platform.OS === "web" ? 4 : 2}
                      padding={4}
                      borderRadius={0}
                      borderWidth={0}
                      backgroundColor="white"
                      variant="filled"
                      size="lg"
                      onValueChange={onChange}
                    >
                      <Select.Item label="JavaScript" value="js" />
                      <Select.Item label="TypeScript" value="ts" />
                      <Select.Item label="Task 123" value="123" />
                    </Select>
                  )}
                  name="parentTaskId"
                />
              </Collapsible> */}
            </Flex>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => {
                // @ts-ignore
                const { opacity, newStatus, name } = getActionFromStatus(value);

                return (
                  <Pressable onPress={() => onChange(newStatus)}>
                    <Flex direction="row" alignItems="center" marginTop={4}>
                      <Text fontSize="md">Status</Text>

                      <Box paddingX={3}>
                        <Icon
                          color="#0891B2"
                          opacity={opacity}
                          as={MaterialCommunityIcons}
                          name={name}
                          size="lg"
                        />
                      </Box>

                      <Text fontSize="md">Task {value}</Text>
                    </Flex>
                  </Pressable>
                );
              }}
              name="status"
            />

            {isMobile && (
              <Flex
                direction="row"
                justifyContent="space-between"
                marginX={-3}
                marginTop={4}
              >
                <Stack direction="row">
                  <IconButton
                    size="lg"
                    isDisabled={shouldShowDesc}
                    icon={<Icon as={AntDesign} name="filetext1" />}
                    onPress={() => setShouldShowDesc(true)}
                  />
                  <IconButton
                    size="lg"
                    isDisabled={shouldShowCategory}
                    icon={<Icon as={AntDesign} name="tago" />}
                    onPress={() => setShouldShowCategory(true)}
                  />
                  {/* <IconButton
                    size="lg"
                    isDisabled={shouldShowLinks}
                    icon={<Icon as={AntDesign} name="link" />}
                    onPress={() => setShouldShowLinks(true)}
                  /> */}
                </Stack>

                {isEditMode && task!.taskId && (
                  <DeleteTaskModal taskId={task!.taskId} />
                )}
              </Flex>
            )}

            {isMobile || (
              <Button
                size="lg"
                marginTop={8}
                borderRadius={0}
                isLoading={isLoading}
                onPress={handleSubmit(handleSaveTask)}
                isDisabled={!isDirty}
                width="100%"
              >
                Save
              </Button>
            )}
          </Flex>
        </ScrollView>
      </LayoutWithBackButton>

      {isMobile && (
        <Button
          size="lg"
          borderRadius={0}
          leftIcon={<Icon as={AntDesign} name="save" />}
          isLoading={isLoading}
          onPress={handleSubmit(handleSaveTask)}
          isDisabled={!isDirty}
        >
          Save
        </Button>
      )}

      <SafeAreaView
        style={{
          flex: 0,
          backgroundColor: !isDirty || isLoading ? "#9CD3E0" : "#0891b2",
        }}
      />
    </>
  );
}
