import { getApps, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAnKNBO7OQICY2yBhi1YdAyaCM8Fjlz7yQ",
  authDomain: "dayful-app.firebaseapp.com",
  projectId: "dayful-app",
  storageBucket: "dayful-app.appspot.com",
  messagingSenderId: "767230891439",
  appId: "1:767230891439:web:8419b45b3a9ccd62178b5a",
};

// const firebaseConfig = {
//   apiKey: process.env.NEXT_PUBLIC_API_KEY,
//   authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
//   projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
//   storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
//   messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
//   appId: process.env.NEXT_PUBLIC_APP_ID,
// };

let firebase;

if (getApps().length < 1) {
  firebase = initializeApp(firebaseConfig);
}
export const db = getFirestore();
export const auth = getAuth(firebase);
